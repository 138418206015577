<template>
    <span>
        <button class="btn btn-info dam-upload-btn" @click="$refs.galleryFile.click()" data-test="upload_image_btn">
            <i class="fas fa-upload"></i>
            {{ $t('buttons.upload_from_computer') }}
        </button>
        <input
          type="file"
          ref="galleryFile"
          style="display: none"
          @change="readFilesOnInputChange"
          :multiple="multiple"
          accept="image/png, image/jpeg"
          data-test="image_upload"
        >
        <app-dam-upload-modal
          v-if="damUploadModal"
          :files="uploadedFiles"
          :count="count"
          @close="closeDamUploadModal"
          @set-media="setMedia"
        >
        </app-dam-upload-modal>
    </span>
</template>

<script>
import moment from 'moment'
import DamUploadModal from '../dam/DamUploadModal'
import MediaService from '../../services/media/MediaService'
import DamApi from '../../api/dam'
import NotifyService from '../../services/NotifyService'
import Config from '../../config/index'

export default {
  name: 'DamUploadButton',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      dataLoaded: false,
      damUploadModal: false,
      uploadedFiles: [],
      count: 0,
      timeoutError: false
    }
  },
  components: {
    appDamUploadModal: DamUploadModal
  },
  methods: {
    readFilesOnInputChange (event) {
      this.readFiles(event.target.files)
    },
    async readFiles (files) {
      this.timeoutError = false
      this.uploadedFiles = []
      this.files = Object.values(files)
      this.count = files.length
      this.showDamUploadModal()

      const chunkSize = MediaService.DAM_UPLOAD_CHUNK_SIZE
      for (let i = 0; i < this.files.length; i += chunkSize) {
        const chunkFiles = this.files.slice(i, i + chunkSize)
        const promises = chunkFiles.map(this.readFile)
        await Promise.all(promises)
      }

      // Clear input file value, prevent to open same file
      this.$refs.galleryFile.value = ''
    },
    readFile (file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = (event) => {
          resolve(this.extractIptc(event.target.result))
          this.loading = false
        }
        reader.readAsDataURL(file)
      })
    },
    extractIptc (image) {
      const bodyFormData = new FormData()
      bodyFormData.set('image', MediaService.dataUriToBlob(image))
      return DamApi(Config.dam.apiExtractTimeout).post('/metadata/image', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          const uploadedFile = {
            image: image,
            headline: response.data.headline,
            byLine: response.data.byLine,
            keywords: response.data.keywords
          }
          if (response.data.dateTimeOriginal) {
            const dateTimeOriginal = moment(response.data.dateTimeOriginal, 'YYYY:MM:DD hh:mm:ss').toISOString()
            uploadedFile.dateTimeOriginal = dateTimeOriginal
          }
          this.uploadedFiles.push(uploadedFile)
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED' && this.timeoutError === false) {
            this.timeoutError = true
            NotifyService.setErrorMessage(this.$t('notify.extract_timeout_error'), '', -1)
          }
          console.log(error)
        })
    },
    showDamUploadModal () {
      this.damUploadModal = true
    },
    closeDamUploadModal () {
      this.damUploadModal = false
    },
    setMedia (assets) {
      this.$emit('set-media', assets)
    },
    fileDragHover (e) {
      e.stopPropagation()
      e.preventDefault()
      var fileDrag = document.getElementById('filedrag')
      var dropZone = document.getElementById('drop-zone')
      if (e.type === 'dragover') {
        fileDrag.classList.add('dragover')
        dropZone.classList.add('active')
      } else {
        fileDrag.classList.remove('dragover')
        dropZone.classList.remove('active')
      }
    },
    fileSelectHandler (e) {
      this.fileDragHover(e)
      var files = e.target.files || e.dataTransfer.files
      this.readFiles(files)
    }
  },
  mounted () {
    var filedrag = document.getElementById('filedrag')
    var dropZone = document.getElementById('drop-zone')
    // Check if filedrag and dropZone is defined
    if (filedrag === null || dropZone === null) {
      return
    }
    filedrag.addEventListener('dragover', this.fileDragHover, false)
    dropZone.addEventListener('dragover', this.fileDragHover, false)
    dropZone.addEventListener('dragleave', this.fileDragHover, false)
    dropZone.addEventListener('drop', this.fileSelectHandler, false)
  }
}
</script>

<style lang="scss" scoped>

</style>
