<template>
  <app-module-view>
    <template slot="body">
      <div>
        <div class="m-b-10">
          <div class="row">
            <div class="col-6">
            </div>
            <div class="text-right col-6">
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('eshopSubscriptionProduct.save') }}
              </button>
              <!-- app-button-delete v-if="subscriptionProduct.id" @deleteRecord="deleteRecord"></app-button-delete-->
              <app-button-close route-name="eshopSubscriptionProduct_list"></app-button-close>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="card card-body">
              <div class="row">
                <div class="col-lg-12">
                  <app-input
                    v-model="subscriptionProduct.name"
                    @blur="$v.subscriptionProduct.name.$touch()"
                    :error="$v.subscriptionProduct.name.$error"
                    id="subscriptionProduct_name"
                    :label="$t('eshopSubscriptionProduct.name')"
                  >
                  </app-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-lg-12">
                      <app-input
                        v-model="subscriptionProduct.slug"
                        @blur="$v.subscriptionProduct.slug.$touch()"
                        :error="$v.subscriptionProduct.slug.$error"
                        id="subscriptionProduct_slug"
                        :label="$t('eshopSubscriptionProduct.slug')"
                      >
                      </app-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <app-select
                        v-model="subscriptionProduct.visibility"
                        :options="visibilityTypeValues"
                        :label="$t('eshopSubscriptionProduct.visibility')"
                        track-by="id"
                        id="subscriptionProduct_visibility"
                      >
                      </app-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <app-select
                        v-model="subscriptionProduct.period"
                        :options="periodTypeValues"
                        :label="$t('eshopSubscriptionProduct.period')"
                        track-by="id"
                        id="subscriptionProduct_period"
                        :convertValue="false"
                      >
                      </app-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <label>{{ $t('eshopSubscriptionProduct.addRelatedProduct') }}</label><br>
                      <app-multi-select
                        v-model="subscriptionProduct.relatedProducts"
                        :options="relatedProducts"
                        :custom-label="prettyProductSelectOption"
                        track-by="id"
                        id="subscriptionProduct_relatedProducts"
                      >
                      </app-multi-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <label>{{ $t('eshopSubscriptionProduct.digitalServices') }}</label><br>
                      <app-multi-select
                        v-model="subscriptionProduct.digitalServices"
                        :options="digitalServices"
                        label="name"
                        track-by="id"
                        id="subscriptionProduct_digitalServices"
                      >
                      </app-multi-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <app-datepicker
                        v-model="subscriptionProduct.publishedSince"
                        id="subscriptionProduct_publishedSince"
                        :label="$t('eshopSubscriptionProduct.published_since')"
                      >
                      </app-datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <app-datepicker
                        v-model="subscriptionProduct.publishedUntil"
                        id="subscriptionProduct_publishedUntil"
                        :label="$t('eshopSubscriptionProduct.published_until')"
                      >
                      </app-datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <app-textarea
                        v-model="subscriptionProduct.setting.email"
                        id="subscriptionProduct_setting_email"
                        :label="$t('eshopSubscriptionProduct.setting.email')"
                      >
                      </app-textarea>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-4">
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.cardNumber"
                        id="subscriptionProduct_setting_cardNumber"
                        :label="$t('eshopSubscriptionProduct.setting.cardNumber')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.company"
                        id="subscriptionProduct_setting_company"
                        :label="$t('eshopSubscriptionProduct.setting.company')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.invoiceAddress"
                        id="subscriptionProduct_setting_invoiceAddress"
                        :label="$t('eshopSubscriptionProduct.setting.invoiceAddress')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.deliveryAddress"
                        id="subscriptionProduct_setting_deliveryAddress"
                        :label="$t('eshopSubscriptionProduct.setting.deliveryAddress')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.notice"
                        id="subscriptionProduct_setting_notice"
                        :label="$t('eshopSubscriptionProduct.setting.notice')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.platform.print"
                        id="subscriptionProduct_setting_platform_print"
                        :label="$t('eshopSubscriptionProduct.setting.platform.print')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.platform.iOS"
                        id="subscriptionProduct_setting_platform.iOS"
                        :label="$t('eshopSubscriptionProduct.setting.platform.iOS')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.platform.android"
                        id="subscriptionProduct_setting_platform_android"
                        :label="$t('eshopSubscriptionProduct.setting.platform.android')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.platform.ePaper"
                        id="subscriptionProduct_setting_platform_ePaper"
                        :label="$t('eshopSubscriptionProduct.setting.platform.ePaper')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.platform.windows8"
                        id="subscriptionProduct_setting_platform_windows8"
                        :label="$t('eshopSubscriptionProduct.setting.platform.windows8')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.subscriptionSetting.giftSubscription"
                        id="subscriptionProduct_subscription_setting_giftSubscription"
                        :label="$t('eshopSubscriptionProduct.subscriptionSetting.giftSubscription')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.subscriptionSetting.aboStartEdition"
                        id="subscriptionProduct_subscription_setting_aboStartEdition"
                        :label="$t('eshopSubscriptionProduct.subscriptionSetting.aboStartEdition')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.trialPeriod.enabled"
                        id="subscriptionProduct_trialPeriod_enabled"
                        :label="$t('eshopSubscriptionProduct.trialPeriod.enabled')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.subscriptionSetting.notifyBeforeNextRecurringPayment"
                        id="subscriptionProduct_subscriptionSetting_notifyBeforeNextRecurringPayment"
                        :label="$t('eshopSubscriptionProduct.subscriptionSetting.notifyBeforeNextRecurringPayment')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.tag.recommended"
                        id="subscriptionProduct_setting_tag_recommended"
                        :label="$t('eshopSubscriptionProduct.setting.tag.recommended')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="px-3">
                      <app-checkbox
                        v-model="subscriptionProduct.setting.tag.convenientOffer"
                        id="subscriptionProduct_setting_tag_convenientOffer"
                        :label="$t('eshopSubscriptionProduct.setting.tag.convenientOffer')"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-body">
              <h3>{{ $t('eshopSubscriptionProduct.image') }}</h3>
              <div class="row">
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-12">
                      <app-select
                        v-model="subscriptionProduct.image.type"
                        :options="productImageTypeValues"
                        :label="$t('eshopSubscriptionProduct.productImageType.title')"
                        track-by="id"
                        id="subscriptionProduct_image_type"
                      >
                      </app-select>
                    </div>
                  </div>
                  <div class="row" v-if="subscriptionProduct.image.type === 'static'">
                    <div class="col-lg-4">
                      <label>{{ $t('eshopSubscriptionProduct.insertImage') }}</label><br>
                      <app-dam-upload-button
                        @set-media="addMedia"
                      >
                      </app-dam-upload-button>
                    </div>
                    <div class="col-lg-8">
                      <app-input
                        v-model="subscriptionProduct.image.uuid"
                        id="subscriptionProduct_image_uuid"
                        :label="$t('eshopSubscriptionProduct.imageUuid')"
                        :readonly="true"
                      >
                      </app-input>
                    </div>
                  </div>
                  <div class="row" v-if="subscriptionProduct.image.type === 'dynamic'">
                    <div class="col-lg-12">
                      <app-select
                        v-model="subscriptionProduct.image.site"
                        :options="productImageSiteTypeValues"
                        :label="$t('eshopSubscriptionProduct.productImageSiteType.title')"
                        track-by="id"
                        id="subscriptionProduct_image_site_type"
                      >
                      </app-select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="row"
                       v-if="subscriptionProduct.image.type === 'static' && subscriptionProduct.image.uuid !== ''">
                    <div
                      class="media-list-item"
                      :style="'background-image: url(' + getDamImagePath(subscriptionProduct.image.uuid) + ')'"
                    >
                    </div>
                  </div>
                  <div class="row"
                       v-if="subscriptionProduct.image.type === 'dynamic' && subscriptionProduct.image.site === 'trend'">
                    <div
                      class="media-list-item"
                      :style="'background-image: url(' + getEditionUrl() + ')'"
                    >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-body">
              <h3>{{ $t('eshopSubscriptionProduct.description.title') }}</h3>
              <div class="row">
                <div class="col-lg-6 form-group">
                  <label>{{ $t('eshopSubscriptionProduct.description.main') }}</label><br>
                  <app-rich-text-editor
                    v-model="subscriptionProduct.description.main"
                    :other-options="richTextEditorConfig"
                    id="subscriptionProduct_description_main"
                  >
                  </app-rich-text-editor>
                </div>
                <div class="col-lg-6 form-group">
                  <label>{{ $t('eshopSubscriptionProduct.description.short') }}</label><br>
                  <app-rich-text-editor
                    v-model="subscriptionProduct.description.short"
                    :other-options="richTextEditorConfig"
                    id="subscriptionProduct_description_short"
                  >
                  </app-rich-text-editor>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 form-group">
                  <label>{{ $t('eshopSubscriptionProduct.description.landingPage') }}</label><br>
                  <app-rich-text-editor
                    v-model="subscriptionProduct.description.landingPage"
                    :other-options="richTextEditorConfig"
                    id="subscriptionProduct_description_landingPage"
                  >
                  </app-rich-text-editor>
                </div>
                <div class="col-lg-6 form-group">
                  <label>{{ $t('eshopSubscriptionProduct.description.summaryPage') }}</label><br>
                  <app-rich-text-editor
                    v-model="subscriptionProduct.description.summaryPage"
                    :other-options="richTextEditorConfig"
                    id="subscriptionProduct_description_summaryPage"
                  >
                  </app-rich-text-editor>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card card-body">
              <h3>{{ $t('eshopSubscriptionProduct.systemInfo') }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                <tr>
                  <td>{{ $t('eshopSubscriptionProduct.id') }}</td>
                  <td>{{ subscriptionProduct.id }}</td>
                </tr>
                <tr>
                  <td>{{ $t('eshopSubscriptionProduct.created_at') }}</td>
                  <td>{{ subscriptionProduct.createdAt | prettyDateTime }}</td>
                </tr>
                <tr>
                  <td>{{ $t('eshopSubscriptionProduct.modified_at') }}</td>
                  <td>{{ subscriptionProduct.modifiedAt | prettyDateTime }}</td>
                </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="px-3">
                  <app-checkbox
                    v-model="subscriptionProduct.enabled"
                    id="subscriptionProduct_enabled"
                    :label="$t('eshopSubscriptionProduct.enabled')"
                  >
                  </app-checkbox>
                </div>
              </div>
            </div>

            <div class="card card-body">
              <h3>{{ $t('eshopSubscriptionProduct.price') }}</h3>
              <div class="row">
                <div class="col-lg-12">
                  <app-input
                    v-model="subscriptionProduct.priceInEuro"
                    @blur="$v.subscriptionProduct.priceInEuro.$touch()"
                    :error="$v.subscriptionProduct.priceInEuro.$error"
                    id="subscriptionProduct_priceInEuro"
                    :no-label="true"
                    type="number"
                    :step="0.01"
                  >
                  </app-input>
                </div>
                <div class="col-lg-12 form-group">
                  <label>{{ $t('eshopSubscriptionProduct.priceDescription') }}</label><br>
                  <app-rich-text-editor
                    v-model="subscriptionProduct.priceDescription"
                    :other-options="richTextEditorConfig"
                    id="subscriptionProduct_priceDescription"
                  >
                  </app-rich-text-editor>
                </div>
                <div class="col-lg-12">
                  <app-select
                    v-model="subscriptionProduct.aboProduct"
                    :options="aboProducts"
                    :label="$t('eshopSubscriptionProduct.aboProduct')"
                    track-by="id"
                    id="subscriptionProduct_aboProduct"
                    optionTitle="name"
                  >
                  </app-select>
                </div>
                <div class="col-lg-12">
                  <app-select
                    v-model="subscriptionProduct.aboPeriodikPrice"
                    :options="aboPeriodikPrices"
                    :label="$t('eshopSubscriptionProduct.aboPeriodikPrice')"
                    id="subscriptionProduct_aboPeriodikPrice"
                  >
                  </app-select>
                </div>
              </div>
            </div>

            <div class="card card-body" v-if="subscriptionProduct.trialPeriod.enabled">
              <h3>{{ $t('eshopSubscriptionProduct.trialPeriod.enabled') }}</h3>
              <div class="row">
                <div class="col-lg-12">
                  <app-input
                    v-model="subscriptionProduct.trialPeriod.priceInEuro"
                    id="subscriptionProduct_trialPeriod_priceInEuro"
                    :no-label="true"
                  >
                  </app-input>
                </div>
                <div class="col-lg-12 form-group">
                  <label>{{ $t('eshopSubscriptionProduct.priceDescription') }}</label><br>
                  <app-rich-text-editor
                    v-model="subscriptionProduct.trialPeriod.description"
                    :other-options="richTextEditorConfig"
                    id="subscriptionProduct_trialPeriod_description"
                  >
                  </app-rich-text-editor>
                </div>
                <div class="col-lg-12">
                  <app-select
                    v-model="subscriptionProduct.trialPeriod_aboProduct"
                    :options="aboProducts"
                    :label="$t('eshopSubscriptionProduct.trialPeriod.aboProduct')"
                    track-by="id"
                    id="subscriptionProduct_trialPeriod_aboProduct"
                    optionTitle="name"
                  >
                  </app-select>
                </div>
                <div class="col-lg-12">
                  <app-select
                    v-model="subscriptionProduct.trialPeriod_aboPeriodikPrice"
                    :options="aboPeriodikPrices"
                    :label="$t('eshopSubscriptionProduct.trialPeriod.aboPeriodikPrice')"
                    id="subscriptionProduct_trialPeriod_aboPeriodikPrice"
                  >
                  </app-select>
                </div>
              </div>
            </div>

            <div class="card card-body">
              <h3>{{ $t('eshopSubscriptionProduct.paymentGateways') }}</h3>
              <div class="row">
                <div class="col-lg-12">
                  <app-select
                    v-model="subscriptionProduct.payment.default"
                    :options="paymentTypeValues"
                    :label="$t('eshopSubscriptionProduct.payment.default')"
                    track-by="id"
                    id="subscriptionProduct_payment_default"
                    noEmptyValue
                  >
                  </app-select>
                </div>
              </div>
              <div class="row">
                <div class="px-3">
                  <app-checkbox
                    v-model="subscriptionProduct.payment.free"
                    id="subscriptionProduct_payment_free"
                    :label="$t('eshopSubscriptionProduct.payment.free')"
                  >
                  </app-checkbox>
                </div>
                <div class="px-3">
                  <app-checkbox
                    v-model="subscriptionProduct.payment.transfer"
                    id="subscriptionProduct_payment_transfer"
                    :label="$t('eshopSubscriptionProduct.payment.transfer')"
                  >
                  </app-checkbox>
                </div>
                <div class="px-3">
                  <app-checkbox
                    v-model="subscriptionProduct.payment.cardpay"
                    id="subscriptionProduct_payment_cardpay"
                    :label="$t('eshopSubscriptionProduct.payment.cardpay')"
                  >
                  </app-checkbox>
                </div>
                <div class="px-3">
                  <app-checkbox
                    v-model="subscriptionProduct.payment.tatrapay"
                    id="subscriptionProduct_payment_tatrapay"
                    :label="$t('eshopSubscriptionProduct.payment.tatrapay')"
                  >
                  </app-checkbox>
                </div>
                <div class="px-3">
                  <app-checkbox
                    v-model="subscriptionProduct.payment.comfortpay"
                    id="subscriptionProduct_payment_comfortpay"
                    :label="$t('eshopSubscriptionProduct.payment.comfortpay')"
                  >
                  </app-checkbox>
                </div>
                <div class="px-3">
                  <app-checkbox
                    v-model="subscriptionProduct.payment.smsRecurring"
                    id="subscriptionProduct_payment_smsRecurring"
                    :label="$t('eshopSubscriptionProduct.payment.smsRecurring')"
                  >
                  </app-checkbox>
                </div>
                <div class="px-3">
                  <app-checkbox
                    v-model="subscriptionProduct.payment.csobCardPay"
                    id="subscriptionProduct_payment_csob_cardpay"
                    :label="$t('eshopSubscriptionProduct.payment.csobCardPay')"
                  >
                  </app-checkbox>
                </div>
                <div class="px-3">
                  <app-checkbox
                    v-model="subscriptionProduct.payment.csobGooglePay"
                    id="subscriptionProduct_payment_csob_googlepay"
                    :label="$t('eshopSubscriptionProduct.payment.csobGooglePay')"
                  >
                  </app-checkbox>
                </div>
                <div class="px-3">
                  <app-checkbox
                    v-model="subscriptionProduct.payment.csobMasterCardMobile"
                    id="subscriptionProduct_payment_csob_mastercard_mobile"
                    :label="$t('eshopSubscriptionProduct.payment.csobMasterCardMobile')"
                  >
                  </app-checkbox>
                </div>
              </div>
            </div>

            <div class="card card-body">
              <h3>{{ $t('eshopSubscriptionProduct.subscriptionSetting.companyProduct') }}</h3>
              <div class="row">
                <div class="col-lg-12">
                  <app-checkbox
                    v-model="subscriptionProduct.subscriptionSetting.companyProduct"
                    id="subscriptionProduct_subscription_setting_companyProduct"
                    :label="$t('eshopSubscriptionProduct.enabled')"
                  >
                  </app-checkbox>
                </div>
                <div class="col-lg-12">
                  <app-input
                    v-model="subscriptionProduct.subscriptionSetting.companyProductCount"
                    id="subscriptionProduct_subscription_setting_companyProductCount"
                    :label="$t('eshopSubscriptionProduct.subscriptionSetting.companyProductCount')"
                  >
                  </app-input>
                </div>
              </div>
            </div>

            <div class="card card-body">
              <h3>{{ $t('eshopSubscriptionProduct.addOnProduct') }}</h3>
              <div class="row">
                <div class="col-lg-12">
                  <app-select
                    v-model="subscriptionProduct.addOnProduct"
                    :options="relatedProducts"
                    :noLabel="true"
                    track-by="id"
                    id="subscriptionProduct_addOnProduct"
                    :custom-title="prettyProductSelectOption"
                  >
                  </app-select>
                </div>
                <div class="col-lg-12 form-group">
                  <label>{{ $t('eshopSubscriptionProduct.addOnProductDescription') }}</label><br>
                  <app-rich-text-editor
                    v-model="subscriptionProduct.addOnProductDescription"
                    :other-options="richTextEditorConfig"
                    id="subscriptionProduct_addOnProductDescription"
                  >
                  </app-rich-text-editor>
                </div>
              </div>
            </div>

            <div class="card card-body">
              <h3>{{ $t('eshopSubscriptionProduct.metadata.title') }}</h3>

              <div class="row">
                <div class="col-lg-12">
                  <app-input
                    v-model="subscriptionProduct.metadata.description"
                    id="subscriptionProduct_metadata_description"
                    :label="$t('eshopSubscriptionProduct.metadata.description')"
                  >
                  </app-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <app-input
                    v-model="subscriptionProduct.metadata.keywords"
                    id="subscriptionProduct_metadata_keywords"
                    :label="$t('eshopSubscriptionProduct.metadata.keywords')"
                  >
                  </app-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import SubscriptionProductModel from '../../model/eshop/SubscriptionProduct'
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import Checkbox from '../form/Checkbox'
import Datepicker from '../form/Datepicker'
import ButtonClose from '../shared/ButtonClose'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import NotifyService from '../../services/NotifyService'
import PeriodTypeMixin from '../mixins/valueObject/eshop/PeriodTypeMixin'
import VisibilityTypeMixin from '../mixins/valueObject/eshop/VisibilityTypeMixin'
import ProductImageTypeMixin from '../mixins/valueObject/eshop/ProductImageTypeMixin'
import ProductImageSiteTypeMixin from '../mixins/valueObject/eshop/ProductImageSiteTypeMixin'
import PaymentTypeMixin from '../mixins/valueObject/eshop/PaymentTypeMixin'
import RichTextEditor from '../form/RichTextEditor'
import TinyMceConfig from '../../services/tinymce/eshop/TinyMceConfig'
import MultiSelect from '../form/select/MultiSelect'
import DamUploadButton from '../shared/DamUploadButton'
import MediaMixin from '../mixins/Media'
import Textarea from '../form/Textarea'
import StoreService from '@/services/eshop/StoreService'
import { mapState } from 'vuex'
import ProductTypeMixin from '@/components/mixins/valueObject/eshop/ProductTypeMixin'

const TREND_EDITION_URL = 'https://covers.nmhmedia.sk/recent/trend/608/0'

export default {
  name: 'SubscriptionProductNew',
  mixins: [
    PeriodTypeMixin,
    VisibilityTypeMixin,
    ProductImageTypeMixin,
    ProductImageSiteTypeMixin,
    MediaMixin,
    PaymentTypeMixin,
    ProductTypeMixin
  ],
  data () {
    return {
      subscriptionProduct: this._.cloneDeep(SubscriptionProductModel),
      richTextEditorConfig: TinyMceConfig.getConfig()
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    aboProducts () {
      return this.$store.getters['eshopAboProduct/all']
    },
    aboPeriodikPrices () {
      return this.$store.getters['eshopAboPeriodikPrice/all']
    },
    relatedProducts () {
      return this.$store.getters['eshopSubscriptionProduct/all']
    },
    digitalServices () {
      return this.$store.getters['eshopDigitalService/all']
    }
  },
  validations: {
    subscriptionProduct: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(180)
      },
      slug: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(180)
      },
      priceInEuro: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(6)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appCheckbox: Checkbox,
    appDatepicker: Datepicker,
    appButtonClose: ButtonClose,
    appRichTextEditor: RichTextEditor,
    appMultiSelect: MultiSelect,
    appDamUploadButton: DamUploadButton,
    appTextarea: Textarea
  },
  created () {
    StoreService.fetchAll()
  },
  methods: {
    prepareSubscriptionProductRequest (subscriptionProduct) {
      delete subscriptionProduct.modifiedBy
      delete subscriptionProduct.modifiedAt
      subscriptionProduct.relatedProducts = subscriptionProduct.relatedProducts.map(product => {
        return product.id
      })
      subscriptionProduct.digitalServices = subscriptionProduct.digitalServices.map(digitalService => {
        return digitalService.id
      })
      subscriptionProduct.price = this.convertPriceInEuroToPrice(this.subscriptionProduct.priceInEuro)
      if (!subscriptionProduct.publishedSince) {
        delete subscriptionProduct.publishedSince
      }
      if (!subscriptionProduct.publishedUntil) {
        delete subscriptionProduct.publishedUntil
      }
      if (subscriptionProduct.trialPeriod.enabled) {
        subscriptionProduct.trialPeriod.price = this.convertPriceInEuroToPrice(this.subscriptionProduct.trialPeriod.priceInEuro)
      }
      return subscriptionProduct
    },
    convertPriceInEuroToPrice (priceInEuro) {
      return (priceInEuro * 100).toPrecision(6)
    },
    async save () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('subscriptionProduct.error.required_fields'))
        return
      }

      // Create product
      this.$store.dispatch('eshopSubscriptionProduct/create', this.prepareSubscriptionProductRequest(this.subscriptionProduct))
        .then(() => {
          this.subscriptionProduct = this.$store.getters['eshopSubscriptionProduct/detail']
          if (this.$store.getters['eshopSubscriptionProduct/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('eshopSubscriptionProduct.message.updated_record'))
            this.$router.push('/shop/subscription/product/' + this.subscriptionProduct.id + '/edit')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['eshopSubscriptionProduct/error'])
          }
        })
        .catch(error => console.log(error))
    },
    deleteRecord () {
      this.$store.dispatch('eshopSubscriptionProduct/deleteRecord', this.subscriptionProduct)
        .then(() => {
          if (this.$store.getters['eshopSubscriptionProduct/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('eshopSubscriptionProduct.message.deleted_record'))
            this.$router.push('/shop/subscription/product')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['eshopSubscriptionProduct/error'])
          }
        })
        .catch(error => console.log(error))
    },
    addMedia (assets) {
      var basename
      assets.forEach(function (asset) {
        basename = asset.basename
      })
      this.subscriptionProduct.image.uuid = basename
    },
    getEditionUrl () {
      if (this.subscriptionProduct.image.type === 'dynamic' && this.subscriptionProduct.image.site === 'trend') {
        return TREND_EDITION_URL
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>

.media-list-item {
  width: 100%;
  height: 220px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  cursor: pointer;
  position: relative;
  margin-top: 1px;
}

</style>
